/* eslint-disable new-cap */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as monaco from 'monaco-editor'

import * as worker from 'monaco-editor/esm/vs/editor/editor.worker'
import * as jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker'
import * as cssWorker from 'monaco-editor/esm/vs/language/css/css.worker'
import * as htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker'
import * as tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker'


// 在初始化之前，先设置MonacoEnvironment
self.MonacoEnvironment = {
    getWorker(_, label) {
        if (label === 'json') {
            return new jsonWorker()
        }
        if (label === 'css' || label === 'scss' || label === 'less') {
            return new cssWorker()
        }
        if (label === 'html' || label === 'handlebars' || label === 'razor') {
            return new htmlWorker()
        }
        if (label === 'typescript' || label === 'javascript') {
            return new tsWorker()
        }
        return new worker()
    }
}


/**
 * Code jsob编辑器
 */
const Code = (props) => {
    const { id, className, language, value, setProps } = props

    const codeEditor = useRef(null)

    const initUi = () => {
        // 导入monaco-editor
        // 自定义主题背景色
        monaco.editor.defineTheme('CodeSampleTheme', {
            base: 'vs',
            inherit: true,
            rules: [{ background: '#fdf6e3' }],
            colors: {
                // 相关颜色属性配置
                // 背景色
                // 'editor.background': '#fdf6e3'
            }
        })
        // 初始化编辑器
        const editor = monaco.editor.create(codeEditor.current, {
            value: JSON.stringify(value, null, 2),
            theme: 'CodeSampleTheme',
            language: language,
            overviewRulerBorder: false,
            automaticLayout: true,
            readOnly: !className.includes('edit'),
            minimap: {
                enabled: false
            },
            lineNumbers: 'on',
            cursorStyle: 'UnderlineThin',
            autoIndent: true,
            glyphMargin: true,
            fontSize: 18,
            scrollbar: {
                verticalScrollbarSize: 1,
                horizontalScrollbarSize: 4
            },
            acceptSuggestionOnCommitCharacter: true,
            acceptSuggestionOnEnter: 'on',
            accessibilityPageSize: 10,
            accessibilitySupport: 'on',
            autoClosingBrackets: 'always',
            autoClosingDelete: 'always',
            autoClosingOvertype: 'always',
            autoClosingQuotes: 'always',
            codeLens: false,
            codeLensFontFamily: '',
            codeLensFontSize: 14,
            colorDecorators: false,
            comments: {
                ignoreEmptyLines: true,
                insertSpace: true
            },
            contextmenu: true,
            columnSelection: false,
            autoSurround: 'never',
            copyWithSyntaxHighlighting: true,
            cursorBlinking: 'Solid',
            cursorSmoothCaretAnimation: true,
            cursorSurroundingLines: 0,
            cursorSurroundingLinesStyle: 'all',
            cursorWidth: 2,
            folding: true,
            links: true,
            renderLineHighlight: 'gutter',
            roundedSelection: false,
            scrollBeyondLastLine: false,
        })
        // 监听内容变化
        // editor.onDidChangeModelContent(() => {
        //     setProps({ value: JSON.parse(editor.getValue()) })
        // })
        // 监听失焦事件
        editor.onDidBlurEditorText(() => {
            try {
                setProps({ value: JSON.parse(editor.getValue()) })
            } catch (error) {
                console.error("JSON parsing error:", error)
                // 可以在这里添加错误处理逻辑，比如显示错误消息
                setProps({ value: editor.getValue() })
            }
        })
    }

    useEffect(() => {
        // 组件挂载后立即执行的方法
        initUi()
    }, [])

    return (
        <div className='h-code relative overflow-hidden h-[340px]' id={id} value={JSON.stringify(value)}>
            <div ref={codeEditor} className='h-full border rounded'></div>
        </div>
    )
}

Code.defaultProps = {}

Code.propTypes = {
    /**
     * id
     */
    id: PropTypes.any,
    /**
     * className
     */
    className: PropTypes.string,
    /**
     * language
     */
    language: PropTypes.string,
    /**
     * value
     */
    value: PropTypes.any,
    /**
     * setValue
     */
    setProps: PropTypes.func
};

export default Code;
